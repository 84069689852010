#signup-confirmation .page-body-container {
    max-width: 550px;
    margin: 0px auto;
    margin-top: 57px;
    padding: 20px 20px 0px 20px;
    text-align: center;
}

#signup-confirmation .confirmation-title {
    color: #018201;
}

#signup-confirmation .confirmation-instruction-message {
    color: #333;
}

#signup-confirmation .resend-button-container {
    margin-top: 40px;
}

#signup-confirmation .resend-button {
    padding: 20px 40px;
    background-color: #045698;
    border-radius: 4px;
    font-size: 16px;
    font-weight: bold;
    color: #fff;
}

#signup-confirmation .resend-button:hover {
    background-color: #3480bd;
}

#signup-confirmation .resend-button:disabled {
    background-color: #018201;
    cursor: default;
}
