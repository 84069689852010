.preview-link {
    width: 100%;
    display: block;
    background-color: #fff;
}

.preview-link .preview-image {
    width: 100%;
    height: auto;
    margin: 0px;
    display: block;
}

.preview-link .preview-content-section {
    padding: 10px;
    background-color: #f2f2f2;
}

.preview-link .preview-content-section .title,
.preview-link .preview-content-section .description,
.preview-link .preview-content-section .hostname {
    margin: 0px;
    padding: 0px;
}

.preview-link .preview-content-section .title {
    font-size: 18px;
    font-weight: bold;
}

.preview-link .preview-content-section .hostname {
    margin-top: 10px;
    font-size: 16px;
    color: #444;
}
