#post-listing .page-body-container {
    max-width: 550px;
    margin: 0px auto;
    position: relative;
}

#post-listing .secondary-header-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
}

#post-listing .secondary-header-row .filter-link {
    margin-left: 20px;
    color: #1763a0;
}

#post-listing .secondary-header-row .filter-link.selected {
    text-decoration: underline;
}

#post-listing .secondary-header-row .filter-link:hover {
    text-decoration: underline;
}

#post-listing .tag-filter-button-container {
    padding: 5px 10px 5px 10px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    background-color: #ffd196;
    border: solid 2px #f79f2e;
    border-radius: 50px;
}

#post-listing .tag-filter-button-container .tag-name {
    font-size: 16px;
    font-weight: bold;
}

#post-listing .tag-filter-button-container .close-button {
    width: 30px;
    height: 30px;
    margin-right: 5px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
}

#post-listing .tag-filter-button-container .close-button:hover {
    background-color: rgba(255, 255, 255, 0.5);
}

#post-listing .compose-button {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px;
    background-color: #fff;
    border-radius: 50px;
    box-shadow: 1px 1px 4px #aaa;
}

#post-listing .compose-button:hover {
    cursor: pointer;
    background-color: #f4f4f4;
}

#post-listing .compose-button .icon-container {
    margin-right: 10px;
    color: gold;
}

#post-listing .compose-button .text {
    margin: 0px;
}

#post-listing .post-list-section {
    margin-top: 10px;
}

#post-listing .post-card-container {
    margin-bottom: 20px;
}

#post-listing .post-card-list-container {
    border-bottom: solid 1px #dfdfdf;
}

#post-listing .pagination {
    padding: 10px;
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

#post-listing .pagination-button {
    padding: 5px 15px;
    background-color: #002440;
    border: none;
    border-radius: 5px;
    box-shadow: 1px 0px 2px 0px #888;
    font-weight: bold;
    color: #fff;
}

@media (min-width: 500px) {
    #post-listing .secondary-header-row {
        padding-left: 0px;
    }
}
