#post-details .page-body-container {
    max-width: 550px;
    margin: 0px auto;
    margin-top: 57px;
    position: relative;
}

#post-details .main-post-section {
    padding-top: 10px;
}

#post-details .post-card .author-section .post-card-avatar {
    width: 30px;
    height: 30px;
    border-radius: 100%;
    background-color: #002440;
}

#post-details .post-card .author-section .post-card-avatar-img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
}

#post-details .link-preview-section {
    margin-bottom: 0px;
}

#post-details .description-section {
    margin-bottom: 0px;
}

#post-details .description {
    padding: 10px 0px;
    white-space: pre-line;
    font-size: 16px;
    border-bottom: solid 0.8px #dfdfdf;
}

#post-details .post-photo-container {
    width: 100%;
    background-color: #fff;
    text-align: center;
}

#post-details .post-photo {
    width: 100%;
    height: auto;
}

#post-details .comments-section {
    padding: 10px;
    background-color: #fff;
}

#post-details .comment-input-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

#post-details .comment-form {
    flex: 1;
}

#post-details .new-comment-textarea-container {
    padding: 10px;
}

#post-details .create-comment-button {
    margin-left: 20px;
    padding: 10px 30px;
    background-color: #1877c3;
    color: #fff;
    font-weight: bold;
    font-size: 14px;
    border-radius: 2px;
    box-shadow: 0px 1px 5px 0px #ccc;
}

#post-details .create-comment-button:disabled {
    background-color: #ccc;
    color: #777;
}

#post-details .create-comment-button:disabled:hover {
    cursor: default;
    background-color: #ccc;
}

#post-details .create-comment-button:hover {
    cursor: pointer;
    background-color: #719fc4
}

#post-details .new-comment-textarea {
    width: 100%;
    padding: 8px;
    background-color: #f3f4f4;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    border: solid 1px #bbb;
    border-radius: 20px;
    font-size: 14px;
    resize: none;
    outline: none;
    -webkit-appearance: none;
}

#post-details .new-comment-textarea::placeholder {
    color: #888;
}

#post-details .tag-button-container {
    display: inline-block;
    margin-right: 10px;
    margin-bottom: 10px;
}

#post-details .tag-button {
    padding: 5px 10px 5px 10px;
    display: inline-block;
    background-color: #ffd196;
    border: solid 2px #f79f2e;
    border-radius: 50px;
}

#post-details .first-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

#post-details .more-options-icon {
    width: 20px;
    height: 20px;
}

#post-details .option-icon {
    width: 18px;
    height: 18px;
    margin-right: 12px;
}

#post-details .dropdown {
    top: 40px;
}

#post-details .dropdown .option-button {
    width: 100%;
    padding: 15px 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: left;
}

#post-details .dropdown .option-button:not(:last-child) {
    border-bottom: solid 1px #ddd;
}

#post-details .hide-options-form .hide-form-header {
    height: 40px;
    padding: 0px 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    box-shadow: 0px 2px 4px 0px #e5e5e5;
}

#post-details .hide-options-form .back-arrow-icon {
    width: 20px;
    height: 20px;
}

#post-details .hide-options-form .hide-reasons-container {
    padding-top: 15px;
    padding-bottom: 5px;
    padding-left: 15px;
}

#post-details .hide-options-form .hide-reason-button {
    width:  initial;
    height: initial;
    margin-right: 10px;
    margin-bottom: 10px;
    padding: 5px 10px;
    display: inline-block;
    background-color: #eee;
    border: solid 2px #ccc;
    border-radius: 25px;
}

#post-details .hide-options-form .other-reason-input {
    width: 80%;
    padding: 10px;
    border: solid 1px #ccc;
    border-radius: 5px;
    font-size: 12px;
}

#post-details .hide-options-form .other-reason-input-container {
    padding: 0px 15px;
    margin-bottom: 15px;
}

#post-details .hide-options-form .hide-form-footer {
    height: 40px;
    padding: 0px 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    box-shadow: 0px -2px 4px 0px #e5e5e5;
    text-align: right;
}

#post-details .hide-options-form .hide-form-footer .submit-button {
    padding: 5px 20px;
    background-color: #004fc5;
    border-radius: 4px;
    box-shadow: 0px 0px 4px 0px #aaa;
    color: #fff;
    font-size: 12px;
    font-weight: bold;
}
