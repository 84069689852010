#login {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: fixed;
    top: 0px;
    left: 0px;
    background-color: #fff;
    overflow-y: scroll;
}

#login .login-page-container {
    min-width: 320px;
    max-width: 500px;
    padding: 40px 0px;
}

#login .header {
    width: 100%;
    position: fixed;
    top: 0px;
    left: 0px;
    padding: 10px 20px;
    letter-spacing: 1px;
    background-color: #000;
}

#login .header .header-inner-container {
    max-width: 1200px;
    margin: 0px auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

#login .logo {
    margin-bottom: 50px;
    font-weight: bold;
    font-size: 38px;
    color: #fff;
}

#login .logo {
    margin: 0px;
    font-size: 24px;
    font-family: "Open Sans", -apple-system, helvetica, arial, sans-serif;
    letter-spacing: -1px;
    font-weight: 800;
    color: #fff;
}

#login .login-form {
    width: 100%;
    margin-top: 57px;
}

#login .form-input {
    width: 100%;
    margin-bottom: 20px;
    padding: 30px 10px 10px 10px;
    background-color: transparent;
    border: none;
    border-bottom: solid 1px #323232;
    border-radius: 0px;
    font-size: 16px;
    box-sizing: border-box;
    color: #323232;
}

#login .form-input::placeholder {
    color: #323232;
}

#login .submit-button-row {
    width: 100%;
    margin-top: 40px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

#login .submit-form-title {
    color: #323232;
}

#login .login-icon {
    width: 60px;
    height: 60px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: #323232;
    border-radius: 50%;
}

#login .login-icon:hover {
    cursor: pointer;
    background-color: #000;
}

#login .alt-registration-row {
    margin-top: 20px;
}

#login .alt-form-title {
    padding-bottom: 5px;
    border-bottom: solid 1px #fff;
    font-size: 16px;
    color: #fff;
}

#login .error-text {
    color: #ff0000;
}

#login .view-demo-link-container {
    margin-top: 30px;
}

#login .view-demo-link {
    color: #fff;
    font-weight: bold;
}

#login .extra-links-container {
    margin-top: 40px;
}

#login .form-link {
    color: #116bb3;
    font-weight: bold;
}

#login .form-link:not(:last-child) {
    margin-right: 20px;
}

#login .form-link:hover {
    text-decoration: underline;
}
