#activity .header {
    border-bottom: solid 0.5px #cdcdcd;
    padding: 10px;
    padding-bottom: 20px;
}

#activity .header .title {
    font-size: 28px;
    margin: 0px;
}

#activity .page-body-container {
    max-width: 550px;
    margin: 0px auto;
    margin-top: 57px;
    padding-top: 20px;
}

#activity .body-content {
}

#activity .nav-bar {
    margin-bottom: 20px;
    padding: 10px;
    padding-bottom: 0px;
}

#activity .nav-bar .nav-button {
    margin-right: 20px;
    padding-bottom: 5px;
    display: inline-block;
    font-weight: bold;
}

#activity .nav-bar .nav-button:hover {
    cursor: pointer;
    border-bottom: solid 2px #bfd4e5;
}

#activity .nav-bar .nav-button.selected {
    border-bottom: solid 2px #bfd4e5;
}

#activity .activity-action-title > p {
    margin: 0px;
    font-size: 16px;
    color: #555;
}

#activity .activity-title > h3 {
    margin: 0px;
}

#activity .activity-card-container {
    display: block;
    padding: 20px 10px;
    background-color: #e0e0e0;
    border-bottom: solid 0.5px #cdcdcd;
    color: #000;
}

#activity .activity-card-container:hover {
    background-color: #f8f8f8;
    text-decoration: none;
}

#activity .activity-card-container.viewed {
    background-color: #fff;
}

#activity .activity-card-container.viewed:hover {
    background-color: #f8f8f8;
}

#activity .pagination {
    padding: 10px;
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

#activity .pagination-button {
    padding: 5px 15px;
    background-color: #002440;
    border: none;
    border-radius: 5px;
    box-shadow: 1px 0px 2px 0px #888;
    font-weight: bold;
    color: #fff;
}

#activity .pagination-button:hover {
    cursor: pointer;
}

#activity .post-card-container {
    margin-bottom: 20px;
    background-color: #fff;
    border-radius: 4px;
    border-top: solid 1px #e5e5e5;
    border-bottom: solid 1px #e0e0e0;
    box-shadow: 0px 0px 4px 0px #dedede;
}
