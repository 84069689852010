#post-card-list .post-card-container {
    margin-bottom: 20px;
    position: relative;
    background-color: #fff;
    border-radius: 4px;
    border-top: solid 1px #e5e5e5;
    border-bottom: solid 1px #e0e0e0;
    box-shadow: 0px 0px 4px 0px #dedede;
}

#post-card-list .pagination {
    padding: 10px;
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

#post-card-list .pagination-button {
    padding: 5px 15px;
    background-color: #002440;
    border: none;
    border-radius: 5px;
    box-shadow: 1px 0px 2px 0px #888;
    font-weight: bold;
    color: #fff;
}

#post-card-list .post-card-comment-thread-container {
    margin: 0px 10px;
    border-top: solid 0.8px #dfdfdf;
}
