.reply-input {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border: none;
}

.reply-input .input-box-container {
    flex-grow: 1;
}

.reply-input textarea {
    margin-left: 20px;
    width: 100%;
    padding: 8px;
    background-color: #f3f4f4;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    border: solid 1px #bbb;
    border-radius: 20px;
    font-size: 14px;
    resize: none;
    outline: none;
    -webkit-appearance: none;
}

.reply-input textarea::placeholder {
    color: #888;
}

.reply-input .reply-comment-button {
    display: inline-block;
    margin-left: 30px;
    padding: 10px 30px;
    background-color: #1877c3;
    color: #fff;
    font-weight: bold;
    font-size: 14px;
    border-radius: 2px;
    box-shadow: 0px 1px 5px 0px #ccc;
}
