.dropdown {
    width: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    background-color: #fff;
    box-shadow: 0px 0px 7px 1px #ddd;
    border-radius: 2px;
    z-index: 100;
}
