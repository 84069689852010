.account-verification-prompt-container {
    min-width: 360px;
    padding: 20px;
}

.account-verification-prompt-container .logo {
    padding: 0px 50px;
    margin-bottom: 20px;
    text-align: center;
    font-size: 34px;
    font-family: 'Open Sans', helvetica, arial;
}

.account-verification-prompt-container .title-container {
    text-align: center;
}

.account-verification-prompt-container .title {
    font-size: 24px;
}

.account-verification-prompt-container .sub-title {
    font-size: 16px;
    margin-bottom: 40px;
}

.account-verification-prompt-container .button-container {
    text-align: center;
}

.account-verification-prompt-container .send-button {
    border-top-left-radius: 50px;
    border-bottom-left-radius: 50px;
    border-top-right-radius: 50px;
    border-bottom-right-radius: 50px;
    font-size: 18px;
    font-weight: bold;
    text-align: center;
    padding: 20px;
    margin-top: 20px;
}

.account-verification-prompt-container .send-button {
    background-color: #116bb3;
    color: #fff;
}

.account-verification-prompt-container .send-button:hover {
    background-color: #2f96e8;
}

.account-verification-prompt-container .send-button:disabled {
    background-color: #48a24c;
    cursor: default;
}

@media only screen and (max-width: 400px) {
    .account-verification-prompt-container {
        min-width: initial;
    }

    .account-verification-prompt-container .logo {
        padding: 0px;
    }
}
