#about .page-body-container {
    max-width: 550px;
    margin: 0px auto;
    padding: 67px 10px 10px 10px;
    background-color: #fff;
}

#about .description {
    margin-bottom: 20px;
    font-size: 20px;
    line-height: 34px;
}
