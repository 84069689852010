.category-selector .category-list-container {
    max-width: 550px;
    margin: 0px auto;
    padding: 0px 20px 20px 20px;
}

.category-selector .alphabet-section {
    margin-bottom: 20px;
}

.category-selector .category-button-container {
    display: inline-block;
    position: relative;
    margin-right: 10px;
    margin-bottom: 10px;
}

.category-selector .category-checkbox {
    position: absolute;
    opacity: 0;
    z-index: -1;
}

.category-selector .category-button {
    padding: 5px 10px 5px 10px;
    display: inline-block;
    background-color: #ffd196;
    border: solid 2px #f79f2e;
    border-radius: 50px;
}

.category-selector .category-button:hover {
    cursor: pointer;
}

.category-selector .category-checkbox:checked + .category-button {
    background-color: #e68100;
}
