.post-card-comment-thread {
    background-color: #fff;
}

.post-card-comment-thread .load-previous-comments-container {
    padding: 10px 0px;
    text-align: center;
}

.post-card-comment-thread .load-previous-comments-button {
    padding: 5px 10px;
    background-color: #e6edf0;
    border-top-left-radius: 50px;
    border-bottom-left-radius: 50px;
    border-top-right-radius: 50px;
    border-bottom-right-radius: 50px;
    box-shadow: 0px 0px 8px -1px #000;
    color: #333;
    font-weight: bold;
}

.post-card-comment-thread .comment-form-container {
    padding: 10px 0px;
}
