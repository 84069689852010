#groups .page-body-container {
    max-width: 550px;
    margin: 0px auto;
    padding-top: 20px;
}

#groups .secondary-header-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

#groups .create-group-button {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px;
    background-color: #fff;
    border-radius: 50px;
    box-shadow: 1px 1px 4px #aaa;
}

#groups .create-group-button:hover {
    background-color: #f4f4f4;
}

#groups .create-group-button .icon-container {
    margin-right: 10px;
    color: gold;
}

#groups .create-group-button .text {
    margin: 0px;
    font-size: 16px;
    font-family: times;
    color: #000;
}

#groups .group-list-section {
    margin-bottom: 40px;
}

#groups .group-list-section .group-list-title {
    margin-bottom: 20px;
}

#groups .group-link {
    display: block;
    padding: 10px 0px;
    color: #000;
}

#groups .group-link:hover {
    background-color: #f4f4f4;
    text-decoration: none;
}

#groups .group-card {
    padding: 10px;
    border-radius: 4px;
}

#groups .description {
    margin-bottom: 0px;
}

#groups .see-all-link {
    color: #116bb3;
    font-size: 16px;
    font-weight: bold;
}

#groups .see-all-link:hover {
    text-decoration: underline;
}

#groups .pagination {
    padding: 10px;
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

#groups .pagination-button {
    padding: 5px 15px;
    background-color: #002440;
    border: none;
    border-radius: 5px;
    box-shadow: 1px 0px 2px 0px #888;
    font-weight: bold;
    color: #fff;
}

.create-group-form .group-name-input-container {
    padding: 10px 0px;
    border-bottom: solid 1px #ccc;
}

.create-group-form .group-name-input {
    width: 100%;
    padding: 10px 5px;
    border: none;
}

.create-group-form .group-description-input-container {
    padding-top: 10px;
}

.create-group-form .group-description-textarea {
    width: 100%;
    min-height: 200px;
    max-height: 500px;
    padding: 10px 5px;
    border: none;
    resize: none;
}

@media (max-width: 500px) {
    #groups .page-body-container {
        padding: 20px 10px;
    }
}
