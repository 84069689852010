.share-link-form {
    width: 100%;
    height: 100%;
    background-color: #fff;
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 2001;
}

.share-link-form .compose-header-container {
    width: 100%;
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 903;
}

.share-link-form .body {
    padding: 10px;
}

.share-link-form input {
    width: 100%;
    padding: 5px;
    background-color: #fff;
    border: solid 1px #ccc;
    border-radius: 2px;
    box-sizing: border-box;
}

.share-link-form .link-preview-container {
    margin-top: 20px;
}
