#signup .page-body-container {
    max-width: 550px;
    margin: 0px auto;
    margin-top: 57px;
    position: relative;
    padding: 0px 20px;
    padding-bottom: 40px;
    z-index: 0;
}

#signup .header {
    width: 100%;
    position: fixed;
    top: 0px;
    left: 0px;
    padding: 10px 20px;
    letter-spacing: 1px;
    background-color: #000;
    z-index: 10;
}

#signup .header .header-inner-container {
    max-width: 1200px;
    margin: 0px auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

#signup .logo {
    margin-bottom: 50px;
    font-weight: bold;
    font-size: 38px;
    color: #fff;
}

#signup .logo {
    margin: 0px;
    font-size: 24px;
    font-family: "Open Sans", -apple-system, helvetica, arial, sans-serif;
    letter-spacing: -1px;
    font-weight: 800;
    color: #fff;
}

#signup .signup-form {
    width: 100%;
    margin-top: 57px;
}

#signup .form-input {
    width: 100%;
    margin-bottom: 20px;
    padding: 30px 10px 10px 10px;
    background-color: transparent;
    border: none;
    border-bottom: solid 1px #323232;
    border-radius: 0px;
    font-size: 16px;
    font-family: times;
    box-sizing: border-box;
    color: #323232;
}

#signup .form-input::placeholder {
    color: #323232;
}

#signup .submit-button-row {
    width: 100%;
    margin-top: 40px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

#signup .submit-form-title {
    color: #323232;
}

#signup .signup-icon {
    width: 60px;
    height: 60px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: #323232;
    border-radius: 50%;
}

#signup .signup-icon:hover {
    cursor: pointer;
    background-color: #000;
}

#signup .alt-registration-row {
    margin-top: 20px;
}

#signup .alt-form-title {
    padding-bottom: 5px;
    border-bottom: solid 1px #fff;
    font-size: 16px;
    color: #fff;
}

#signup .error-text {
    color: #ff0000;
}

#signup .extra-links-container {
    margin-top: 20px;
}

#signup .form-link {
    color: #116bb3;
    font-weight: bold;
}

#signup .form-link:not(:last-child) {
    margin-right: 20px;
}

#signup .form-link:hover {
    text-decoration: underline;
}

#signup .label {
    margin: 10px 0px;
    padding: 0px;
}

#signup .birthdate-selector-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

#signup .birthdate-selector-container .field-container {
    flex-grow: 1;
}

#signup .birthdate-selector-container .field-container:not(:last-child) {
    margin-right: 20px;
}

#signup select {
    width: 100%;
    padding: 10px;
    border: solid 1px #323232;
    border-radius: 4px;
    font-family: times;
}
