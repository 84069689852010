.profile-edit-form .banner-image-container {
    max-width: 550px;
    margin: 0px auto;
}

.profile-edit-form .profile-banner-image {
    width: 100%;
    height: auto;
}

.profile-edit-form .profile-data-section {
    padding: 10px;
}

.profile-edit-form .user-name {
    font-weight: bold;
    font-size: 18px;
}

.profile-edit-form .user-headline {
    margin-bottom: 20px;
}

.profile-edit-form .profile-data-group {
    display: flex;
    margin-bottom: 15px;
}

.profile-edit-form .profile-data-section .icon-container {
    margin-right: 10px;
}

.profile-edit-form .profile-data-section .icon {
    width: 16px;
    height: 16px;
}

.profile-edit-form .profile-data-section .header-label {
    color: #777;
}

.profile-edit-form .selected-image-section {
    height: 300px;
    display: flex;
    align-items: center;
    background-color: #000;
    overflow: hidden;
}

.profile-edit-form .selected-image {
    width: 100%;
    height: 300px;
    object-fit: cover;
}

.profile-edit-form .dropzone {
    width: 100%;
    height: 100%;
}

.profile-edit-form .image-upload-icon {
    width: 150px;
    height: auto;
}

.profile-edit-form .upload-icon-section {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #73c1ff;
}

.profile-edit-form .upload-icon-section p {
    padding-left: 20px;
    padding-right: 20px;
}

.profile-edit-form .edit-image-button:not(:last-child) {
    margin-right: 20px;
}

.profile-edit-form .edit-image-button-row {
    text-align: center;
}

.profile-edit-form .form-container {
    max-width: 550px;
    margin: 0px auto;
    padding: 10px;
}

.profile-edit-form .profile-input-section {
    display: flex;
    flex-direction: row;
}

.profile-edit-form .profile-input-section .icon-container {
    margin-right: 10px;
}

.profile-edit-form .profile-input-section .icon {
    width: 16px;
    height: 16px;
}

.profile-edit-form .profile-input-section .header-label {
    color: #777;
}

.profile-edit-form .form-textarea.headline {
    min-height: 50px;
}

.profile-edit-form .form-textarea {
    width: 100%;
    min-height: 100px;
    border: solid 1px #ccc;
    border-radius: 2px;
    box-sizing: border-box;
    resize: none;
}

.profile-edit-form .form-input-container,
.profile-edit-form .form-textarea-container {
    margin-bottom: 15px;
}

.profile-edit-form .form-input {
    width: 100%;
    padding: 10px;
    border: solid 1px #ccc;
    border-radius: 2px;
    box-sizing: border-box;
}

.profile-edit-form .input-fields-container {
    flex-grow: 1;
}

.profile-edit-form .error-text {
    color: #ff0000;
}
