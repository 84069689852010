.dropdown-nav-menu {
    color: #fff;
}

.dropdown-nav-menu ul {
    list-style-type: none;
    margin: 0px;
    padding: 0px;
}

.dropdown-nav-menu li {
    padding: 5px 20px;
    font-size: 18px;
}

.dropdown-nav-menu li:hover {
    background-color: #1a1a1a;
    cursor: pointer;
}

.dropdown-nav-menu a {
    padding: 10px 0px;
    display: block;
    color: #fff;
}

.dropdown-nav-menu .logout-link {
    padding: 15px 20px;
}
