.login-prompt-container {
    min-width: 360px;
    padding: 20px;
}

.login-prompt-container .logo {
    padding: 0px 50px;
    margin-bottom: 20px;
    text-align: center;
    font-size: 34px;
}

.login-prompt-container .title-container {
    text-align: center;
}

.login-prompt-container .title {
    font-size: 24px;
}

.login-prompt-container .sub-title {
    font-size: 16px;
    margin-bottom: 40px;
}

.login-prompt-container .link-button {
    border-top-left-radius: 50px;
    border-bottom-left-radius: 50px;
    border-top-right-radius: 50px;
    border-bottom-right-radius: 50px;
    font-size: 18px;
    font-weight: bold;
    display: block;
    text-align: center;
    padding: 20px;
    margin-top: 20px;
}

.login-prompt-container .link-button.login {
    background-color: #000;
    color: #fff;
}

.login-prompt-container .link-button.login:hover {
    background-color: #333;
}

.login-prompt-container .link-button.signup {
    color: #222;
    border: solid 2px #222;
}

.login-prompt-container .link-button.signup:hover {
    color: #fff;
    border-color: #000;
    background-color: #000;
}

@media only screen and (max-width: 400px) {
    .login-prompt-container {
        min-width: initial;
    }

    .login-prompt-container .logo {
        padding: 0px;
    }
}
