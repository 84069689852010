#group-page .page-body-container {
    max-width: 550px;
    margin: 0px auto;
    margin-top: 57px;
    padding: 20px;
}

#group-page .group-header-section {
    padding: 15px;
    box-shadow: 0px 0px 5px 0px #ddd;
    border-radius: 4px;
}

#group-page .group-nav-bar-section {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 15px 0px;
}

#group-page .join-button,
#group-page .unjoin-button {
    font-size: 16px;
    font-family: times;
}

#group-page .group-nav-bar-section .nav-link {
    margin-right: 40px;
    padding-bottom: 1px;
    font-size: 16px;
    border-bottom: solid 1px #555;
    color: #000;
}

#group-page .group-nav-bar-section .nav-link:hover {
    text-decoration: none;
}

button.invitation-dialog-action-button {
    color: #fff;
}

.invitation-email-input {
    width: 100%;
    padding: 5px 10px;
    box-sizing: border-box;
}

#group-page .group-second-button-row {
    display: flex;
    flex-direction: row;
}

#group-page .group-second-button-row > div {
    margin-right: 40px;
}

#group-page .group-second-button-row button {
    padding: 0px;
    font-family: time;
    font-size: 16px;
}

#group-page .edit-group-button,
#group-page .alert-dialog-button {
    font-size: 16px;
    font-family: times;
    padding: 0px;
}

#group-page .page-body-container .body-content {
    margin-top: 20px;
}

#group-page .post-card-container {
    margin-bottom: 20px;
    background-color: #fff;
    border-radius: 4px;
    border-top: solid 1px #e5e5e5;
    border-bottom: solid 1px #e0e0e0;
    box-shadow: 0px 0px 4px 0px #dedede;
}

#group-page .create-post-button-container {
    display: flex;
    flex-direction: row;
    align-items: center;
}

#group-page .create-post-button {
    display: inline-block;
    padding: 10px;
    margin: 10px 0px 30px 0px;
    background-color: #fff;
    border-radius: 50px;
    box-shadow: 1px 1px 4px #aaa;
}

#group-page .create-post-button {
    color: #000;
}

#group-page .create-post-button:hover {
    text-decoration: none;
    background-color: #eee;
}

#group-page .create-post-button .icon-container {
    margin-right: 10px;
}

#group-page .pagination {
    padding: 10px;
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

#group-page .pagination-button {
    padding: 5px 15px;
    background-color: #002440;
    border: none;
    border-radius: 5px;
    box-shadow: 1px 0px 2px 0px #888;
    font-weight: bold;
    color: #fff;
}
