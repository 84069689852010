#admin .page-wrapper {
    padding: 10px;
    margin-top: 57px;
}

#admin .label {
    margin-bottom: 5px;
}

#admin .field-container {
    margin-bottom: 20px;
}

#admin .input-field {
    width: 100%;
    padding: 5px;
    border: solid 1px #ccc;
    border-radius: 2px;
    box-sizing: border-box;
}

#admin .select-field {
    padding: 5px;
    border: solid 1px #ccc;
    border-radius: 2px;
}

#admin .error-message {
    margin-top: 10px;
    display: block;
    color: #ff0000;
}

#admin .submit-button {
    margin-top: 10px;
    padding: 10px;
    border-radius: 2px;
    background-color: #13ae00;
    font-weight: bold;
    color: #fff;
}
