#privacy-policy .page-body-container {
    max-width: 550px;
    padding: 20px;
    margin: 0px auto;
    margin-top: 57px;
}

#privacy-policy .content-container a {
    color: #0065ff;
    text-decoration: underline;
}

#privacy-policy .content-container table, td, th {
  border: 1px solid black;
}

#privacy-policy .content-container table {
    border-collapse: collapse;
}
