#category-preferences .page-body-container {
    max-width: 550px;
    margin: 0px auto;
    margin-top: 57px;
    margin-bottom: 40px;
    position: relative;
}

#category-preferences .page-title {
    padding-top: 20px;
    margin-bottom: 20px;
}

@media (max-width: 500px) {
    #category-preferences .page-body-container {
        padding: 20px;
    }
}
