#tag-filters {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0px;
    left: 0px;
    bottom: 0px;
    right: 0px;
    background-color: #fff;
    z-index: 2000;
    -webkit-overflow-scrolling: touch;
}

#tag-filters .compose-header-container {
    width: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 901;
}

#tag-filters .content-body {
    width: 100%;
    height: 100%;
    margin-top: 57px;
    position: relative;
}

#tag-filters .content-body-inner {
    width: 100%;
    height: calc(100% - 57px);
    position: absolute;
    top: 0px;
    overflow-x: hidden;
    overflow-y: auto;
}

#tag-filters .alphabet-index-container {
    max-width: 550px;
    margin: 10px auto 20px auto;
    text-align: center;
    font-size: 12px;
}

#tag-filters .alphabet-index {
    margin-right: 5px;
}

#tag-filters .disabled-index {
    color: #aaa;
}

#tag-filters .tag-list-container {
    max-width: 550px;
    margin: 0px auto;
    padding: 0px 20px 20px 20px;
}

#tag-filters .tag-button-container {
    display: inline-block;
    margin-right: 10px;
    margin-bottom: 10px;
}

#tag-filters .tag-button {
    padding: 5px 10px 5px 10px;
    display: inline-block;
    background-color: #ffd196;
    border: solid 2px #f79f2e;
    border-radius: 50px;
}

#tag-filters .tag-button:hover {
    cursor: pointer;
}

#tag-filters .tag-button.chosen {
    background-color: #e68100;
}
