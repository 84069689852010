.group-selector {
    max-width: 550px;
    margin: 0px auto;
}

.group-selector .content-title {
    padding: 10px 0px;
}

.group-selector .search-bar {
    width: 100%;
    padding: 10px 5px;
    margin-bottom: 20px;
    border: solid 2px #ccc;
    border-radius: 4px;
    font-size: 16px;
    box-sizing: border-box;
}

.group-selector .group-button {
    width: 100%;
    display: block;
    padding: 10px 0px;
    text-align: left;
}

.group-selector .group-button:hover {
    background-color: #f4f4f4;
}

.group-selector .group-button .name {
    font-weight: bold;
    font-size: 16px;
}

.group-selector .group-button .description {
    font-size: 14px;
}

.group-selector .join-group-section {
    max-width: 500px;
}

.group-selector .join-group-link {
    margin-bottom: 20px;
    padding: 10px 20px;
    display: inline-block;
    border: solid 2px #116bb3;
    border-radius: 4px;
    background-color: #fff;
    text-align: center;
    font-weight: bold;
    font-size: 20px;
    color: #116bb3;
}

.group-selector .join-group-link:hover {
    background-color: #116bb3;
    color: #fff;
}

.group-selector .join-group-subtitle {
    color: #333;
    font-size: 20px;
}
