#profile-page .page-body-container {
    max-width: 550px;
    margin: 0px auto;
    margin-top: 57px;
}

#profile-page .banner-image-container {
    width: 100%;
    height: 300px;
    display: flex;
    align-items: center;
    overflow: hidden;
    background-color: #252525;
}

#profile-page .profile-banner-image {
    width: 100%;
    height: 300px;
    object-fit: cover;
}

#profile-page .profile-data-section {
    padding: 10px;
}

#profile-page .profile-data-section .first-row {
    margin-bottom: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

#profile-page .user-name-headline-container {
    margin-right: 20px;
}

#profile-page .edit-profile-button-container {
}

#profile-page .edit-profile-button {
    min-width: 100px;
    padding: 10px 0px;
    display: inline-block;
    border: solid 3px #333;
    border-radius: 4px;
    font-weight: bold
}

#profile-page .edit-profile-button:hover {
    background-color: #333;
    color: #fff;
    cursor: pointer;
}

#profile-page .user-name {
    margin-bottom: 2px;
    font-weight: bold;
    font-size: 18px;
}

#profile-page .user-headline {
    font-size: 16px;
}

#profile-page .user-bio {
    white-space: pre-line;
}

#profile-page .profile-data-group {
    display: flex;
    margin-bottom: 15px;
}

#profile-page .profile-data-section .icon-container {
    margin-right: 10px;
}

#profile-page .profile-data-section .icon {
    width: 16px;
    height: 16px;
}

#profile-page .profile-data-section .header-label {
    color: #777;
}
