.groups-list .group-link {
    display: block;
    padding: 10px 0px;
    color: #000;
}

.groups-list .group-link:hover {
    background-color: #f4f4f4;
    text-decoration: none;
}

.groups-list .description {
    margin: 0px;
}
