.comment-input-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.comment-input-row .form-container {
    flex-grow: 1;
}

.comment-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.new-comment-textarea-container {
    padding: 10px;
}

.create-comment-button {
    margin-left: 20px;
    padding: 10px 30px;
    background-color: #1877c3;
    color: #fff;
    font-weight: bold;
    font-size: 14px;
    border-radius: 2px;
    box-shadow: 0px 1px 5px 0px #ccc;
}

.create-comment-button:disabled {
    background-color: #ccc;
    color: #777;
}

.create-comment-button:disabled:hover {
    cursor: default;
    background-color: #ccc;
}

.create-comment-button:hover {
    cursor: pointer;
    background-color: #719fc4
}

.new-comment-textarea {
    width: 100%;
    padding: 8px;
    background-color: #f3f4f4;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    border: solid 1px #bbb;
    border-radius: 20px;
    font-size: 14px;
    resize: none;
    outline: none;
    -webkit-appearance: none;
}

.new-comment-textarea::placeholder {
    color: #888;
}