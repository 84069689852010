#group-invitation .page-body-container {
    max-width: 550px;
    margin: 0px auto;
    margin-top: 57px;
    padding: 20px 20px 0px 20px;
}

#group-invitation .header {
    width: 100%;
    position: fixed;
    top: 0px;
    left: 0px;
    padding: 10px 20px;
    letter-spacing: 1px;
    background-color: #000;
}

#group-invitation .header .header-inner-container {
    max-width: 1200px;
    margin: 0px auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

#group-invitation .logo {
    margin-bottom: 50px;
    font-weight: bold;
    font-size: 38px;
    color: #fff;
}

#group-invitation .logo {
    margin: 0px;
    font-size: 24px;
    font-family: "Open Sans", -apple-system, helvetica, arial, sans-serif;
    letter-spacing: -1px;
    font-weight: 800;
    color: #fff;
}
