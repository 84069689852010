.create-group-form .dialog-open-button {
    width: 100%;
    max-width: 400px;
    display: block;
    margin-bottom: 20px;
    padding: 10px 0px;
    background-color: #f0f0f0;
    font-weight: bold;
    font-size: 14px;
    border: solid 2px #ccc;
    border-radius: 3px;
}

.create-group-form .dialog-open-button:hover {
    background-color: #f7f7f7;
}

.create-group-form .category-button {
    padding: 5px 10px 5px 10px;
    display: inline-block;
    background-color: #ffd196;
    border: solid 2px #f79f2e;
    border-radius: 50px;
}

.create-group-form .category-button.chosen {
    background-color: #e68100;
}

.create-group-form .group-category-buttons-section .category-button {
    margin: 0px 10px 10px 0px;
}

.create-group-form .error-message {
    color: #ff0000;
}

@media (max-width: 650px) {
    .create-group-form .dialog-open-button {
        margin: 0px auto;
        margin-bottom: 20px;
    }

    .create-group-form .group-category-buttons-section {
        text-align: center;
    }
}
