#all-groups {
    margin-bottom: 40px;
}

#all-groups .page-body-container {
    max-width: 550px;
    margin: 0px auto;
    margin-top: 57px;
    margin-bottom: 40px;
    position: relative;
}

#all-groups .page-title {
    padding: 20px 0px;
}

@media (max-width: 500px) {
    #all-groups .page-body-container {
        padding-left: 10px;
        padding-right: 10px;
    }
}
