#compose-post-form {
    width: 100%;
    height: 100%;
}

#compose-post-form .compose-form-wrapper {
    width: 100%;
}

#compose-post-form .compose-header-container {
    width: 100%;
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 900;
}

#compose-post-form .compose-post-form-body-container {
    max-width: 550px;
    height: 100%;
    margin: 0px auto;
    position: relative;
    background-color: #fff;
    box-shadow: 0px 0px 4px 0px #aaa;
}

#compose-post-form .form-section {
    margin-top: 57px;
    padding-top: 10px;
    flex-grow: 1;
    display: flex;
}

#compose-post-form .compose-form {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

#compose-post-form .compose-form .title-section {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}

#compose-post-form .compose-form .link-preview-section {
    flex-grow: 1;
}

#compose-post-form .compose-form .body-section {
    display: flex;
    flex-direction: column;
    flex-grow: 3;
}

#compose-post-form .remove-link-preview-section {
    padding: 0px 15px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin: 10px 0px;
}

#compose-post-form .remove-link-preview-section .remove-link-preview-button {
    width: 20px;
    height: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 5px;
    background-color: #fff;
    border-radius: 50%;
    box-shadow: 0px 1px 5px #555;
}

#compose-post-form .hide {
    display: none;
}

#compose-post-form .title-field-section {
    background-color: #fff;
    border-bottom: solid 1px #ddd;
}

#compose-post-form .title-field-section .remove-title-button {
    margin: 0px 0px 10px 10px;
    padding: 10px;
    display: inline-block;
    background-color: #fff;
    border-radius: 50px;
    box-shadow: 1px 1px 4px #aaa;
    font-size: 12px;
    color: #555;
}

#compose-post-form .post-title-input {
    width: 100%;
    min-height: 100px;
    flex-grow: 1;
    padding: 10px;
    background-color: #fdfdfd;
    border: none;
    font-size: 18px;
    font-weight: bold;
    resize: none;
    box-sizing: border-box;
}

#compose-post-form .post-body-input {
    width: 100%;
    padding: 10px;
    flex-grow: 3;
    min-height: 300px;
    background-color: #fff;
    border: none;
    font-size: 16px;
    resize: none;
    box-sizing: border-box;
}

#compose-post-form .cancel-form-button {
    color: #fff;
    font-weight: bold;
}

#compose-post-form .post-form-button {
    color: #fff;
    font-weight: bold;
}

#compose-post-form .tags-container {
    padding: 10px;
}

#compose-post-form .tag-button {
    padding: 5px 10px 5px 10px;
    display: inline-block;
    background-color: #ffd196;
    border: solid 2px #f79f2e;
    border-radius: 50px;
    margin-bottom: 10px;
    margin-right: 5px;
}

#compose-post-form .tag-button .inner-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

#compose-post-form .tag-button .remove-icon-container {
    width: 20px;
    height: 20px;
    margin-right: 5px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

#compose-post-form .tag-button .remove-icon-container:hover {
    cursor: pointer;
    background-color: rgba(255,255,255,0.5);
    border-radius: 50%;
}

#compose-post-form .tag-button .remove-icon {
    width: 10px;
    height: 10px;
}

#compose-post-form .add-post-media-button-container {
    padding: 10px;
    display: inline-block;
    font-size: 16px;
}

#compose-post-form .add-post-media-button {
    display: inline-block;
    padding: 10px;
    background-color: #fff;
    border-radius: 50px;
    box-shadow: 1px 1px 4px #aaa;
    font-size: 14px;
}

#compose-post-form .add-post-media-button .inner-container {
    display: flex;
    flex-direction: row;
    align-items: center;
}

#compose-post-form .add-post-media-button .icon-container {
    margin-right: 5px;
}

#compose-post-form .add-post-media-button .icon {
    width: 20px;
    height: 20px;
}

#compose-post-form .dropzone-button {
    width: 80px;
    min-height: auto;
    padding: 10px;
    background-color: #fff;
    border-radius: 50px;
    border: none;
    box-shadow: 1px 1px 4px #aaa;
    display: inline-block;
}

#compose-post-form .dropzone-button p {
    margin: 0px;
    font-size: 14px;
    font-family: Arial;
}

#compose-post-form .dropzone-button svg {
    display: none;
}

#compose-post-form .dropzone-button > div {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
}

#compose-post-form .add-post-media-button-container .photo-dropzone-button-container {

}

#compose-post-form .photo-dropzone-button-container .icon-container {
    margin-top: 10px;
    margin-left: 12px;
    position: absolute;
    z-index: 1;
}

#compose-post-form .uploaded-photo-preview-container {
    overflow: hidden;
    background-color: #fff;
    text-align: center;
}

#compose-post-form .uploaded-photo-preview {
    max-width: 100%;
    height: auto;
}

#compose-post-form .remove-uploaded-photo-button-container {
    padding: 10px 0px;
}

#compose-post-form .remove-uploaded-photo-button {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 0px auto;
    padding: 10px;
    background-color: #fff;
    border-radius: 50px;
    box-shadow: 0px 1px 5px #555;
}

#compose-post-form .remove-uploaded-photo-button:hover {
    background-color: #f3f3f3;
    cursor: pointer;
}

#compose-post-form .remove-uploaded-photo-icon {
    margin-right: 5px;
    font-size: 16px;
}

#compose-post-form .remove-uploaded-photo-button-text {
    font-size: 14px;
}

#compose-post-form .error-banner {
    width: 100%;
    padding: 10px;
    position: fixed;
    bottom: 0px;
    left: 0px;
    z-index: 400;
    background-color: #333;
    color: #fff;
}

#compose-post-form .dialog-open-button {
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-left: 10px;
    padding: 10px 20px;
    border: solid 2px #ccc;
    border-radius: 4px;
    font-size: 16px;
    background-color: #fff;
    color: #333;
}

#compose-post-form .dialog-open-button:hover {
    background-color: #f4f4f4;
}

#compose-post-form .dialog-open-button .text {
    margin-right: 20px;
}

#compose-post-form .dialog-open-button:disabled {
    background-color: #ddd;
    color: #888;
}

#compose-post-form .dialog-open-button:disabled:hover {
    cursor: default;
}
