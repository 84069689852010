#account-verification .page-body-container {
    max-width: 550px;
    margin: 0px auto;
    margin-top: 57px;
    padding: 20px 20px 0px 20px;
    text-align: center;
}

#account-verification .body-content {
    margin-top: 40px;
    color: #555;
}

#account-verification .account-verified-message {
    color: #018201;
}
