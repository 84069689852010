.main-page .page-body-container {
    max-width: 550px;
    margin: 0px auto;
    margin-top: 57px;
    position: relative;
}

.main-page .page-segment-nav {
    display: flex;
    flex-direction: row;
}

.main-page .page-segment-nav .segment-button {
    flex-grow: 1;
    padding: 10px 0px;
    text-align: center;
    border-bottom: solid 2px #ccc;
    font-weight: bold;
    color: #333;
    box-sizing: content-box;
}

.main-page .page-segment-nav .segment-button.selected {
    background-color: #f4f4f4;
    border-bottom-width: 3px;
    padding-bottom: 7px;
}

.main-page .page-segment-nav .segment-button {
    border-left: solid 2px #ccc;
}

.main-page .page-segment-nav .segment-button:last-child {
    border-right: solid 2px #ccc;
    border-bottom-right-radius: 4px;
}

.main-page .page-segment-nav .segment-button:first-child {
    border-bottom-left-radius: 4px;
}

.main-page .page-segment-nav .segment-button:hover {
    background-color: #f4f4f4;
    border-bottom-width: 3px;
    padding-bottom: 7px;
}
