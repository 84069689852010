.parent-thread .parent-comment-section:not(:last-child) {
    margin-bottom: 10px;
}

.parent-thread .children-comment-section {
    margin-left: 30px;
    padding-left: 10px;
    border-left: solid 5px #ddd;
}

.parent-thread .child-comment {
    margin-bottom: 10px;
}

.comment-thread .comment-form-container {
    margin: 10px 0px;
}
