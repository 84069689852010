.global-footer {
    max-width: 550px;
    margin: 0px auto;
    margin-top: 200px;
    padding: 0px 20px 25px 20px;
    text-align: center;
    font-size: 14px;
    color: #777;
}

.global-footer .text-block {
    position: inline-block;
    margin-right: 10px;
    color: #777;
}

.global-footer .text-block:last-child {
    margin-right: 0px;
}
