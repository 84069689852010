#account .header {
    border-bottom: solid 0.5px #cdcdcd;
    padding: 10px;
    padding-bottom: 20px;
}

#account .header .title {
    font-size: 28px;
    margin: 0px;
}

#account .page-body-container {
    max-width: 550px;
    margin: 0px auto;
    padding-top: 57px;
}

#account .body-content {
    padding: 10px;
}

#account .basic-info-form {
    margin-bottom: 20px;
    padding-bottom: 10px;
    border-bottom: solid 1px #ccc;
}

#account .label {
    margin-bottom: 10px;
    font-weight: bold;
    font-size: 16px;
}

#account .label-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

#account .edit-button {
    display: flex;
    flex-direction: row;
    align-items: center;
    text-decoration: underline;
}

#account .edit-button:hover {
    text-decoration: underline;
    cursor: pointer;
}

#account .edit-icon {
    width: 12px;
    height: 12px;
    margin-right: 5px;
}

#account .edit-button-title {
    font-size: 14px;
}

#account .input-section-container {
    margin-bottom: 15px;
}

#account .attribute-input {
    width: 100%;
    margin-bottom: 10px;
    padding: 10px;
    background-color: #fff;
    border: solid 1px #ccc;
    border-radius: 2px;
    box-sizing: border-box;
}

#account .form-execute-button {
    margin-right: 10px;
    padding: 10px;
    font-size: 14px;
    font-weight: bold;
    border-radius: 4px;
}

#account .form-execute-button.cancel-button {
    background-color: #ccc;
    color: #000;
}

#account .form-execute-button.submit-button {
    background-color: #13ae00;
    color: #fff;
}

#account .submissionErrors {
    margin-top: 10px;
    color: #ff0000;
}

#account .attribute-value {
    padding: 10px;
    background-color: #efefef;
    border-radius: 2px;
}

#account .attribute-section.delete-account-section {
    margin-top: 50px
}

#account .attribute-section.delete-account-section .label {
    padding-bottom: 10px;
    border-bottom: solid 1px #ccc;
}

#account .password-placeholder {
    margin-bottom: 10px;
    padding: 10px;
    background-color: #efefef;
    border-radius: 2px;
    font-size: 12px;
}

#account .delete-account-button {
    padding: 10px;
    border-radius: 4px;
    font-weight: bold;
    font-size: 14px;
    background-color: #ff0000;
    color: #fff;
}

#account .error-text {
    color: #ff0000;
}

.react-confirm-alert-overlay {
    padding: 10px;
    background-color: rgba(0, 0, 0, 0.8);
}

.delete-account-dialog {
    padding: 20px;
    background-color: #fff;
    border-radius: 2px;
}

.delete-account-dialog .button-row {
    margin-top: 30px;
}

.delete-account-dialog .cancel-button {
    padding: 5px 10px;
    margin-right: 10px;
    font-size: 14px;
    font-weight: bold;
    background-color: #555;
    color: #fff;
    border-radius: 3px;
}

.delete-account-dialog .confirm-button {
    padding: 5px 10px;
    font-size: 14px;
    font-weight: bold;
    background-color: #ff0000;
    color: #fff;
    border-radius: 3px;
}

.delete-account-dialog .cancel-button:hover,
.delete-account-dialog .confirm-button:hover {
    cursor: pointer;
}

#account .notification-preferences {
    margin-bottom: 20px;
    border-bottom: solid 1px #ccc;
}

#account .preference-row-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0px 0px 10px 0px;
}

#account .preference-row-container .row-label {
    margin: 0px;
}

#account .section {
    border-bottom: solid 1px #ccc;
    padding-bottom: 20px;
    margin-bottom: 20px;
}

#account .resend-verification-button {
    padding: 10px 20px;
    background-color: #116bb3;
    border-radius: 4px;
    font-size: 16px;
    font-weight: bold;
    color: #fff;
}

#account .resend-verification-button:hover {
    background-color: #2f96e8;
}

#account .resend-verification-button:disabled {
    background-color: #48a24c;
    cursor: default;
}

#account .MuiSwitch-colorPrimary.Mui-checked {
    color: #116bb3;
}

#account .MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track {
    background-color: #116bb3;
}

#account .MuiTypography-body1 {
    font-family: times;
}
