#group-members .page-body-container {
    max-width: 550px;
    margin: 0px auto;
    margin-top: 57px;
    padding: 20px;
}

#group-members .member-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0px;
}

#group-members .member-row .member-name {
    flex-grow: 1;
}

#group-members .member-row .member-name .member-link {
    display: block;
    color: #000;
}

#group-members .admin-section {
    margin-bottom: 20px;
    padding: 20px;
    border: solid 1px #ccc;
    border-radius: 4px;
}

#group-members .moderator-section {
    margin-bottom: 20px;
    padding: 20px;
    border: solid 1px #ccc;
    border-radius: 4px;
}
