.comment-card {
    position: relative;
    background-color: #fff;
    padding: 10px;
    border-bottom: solid 0.5px #ddd;
    border-radius: 2px;
}

.comment-card .author-section {
    display: flex;
    flex-wrap: nowrap;
    margin-bottom: 10px;
}

.comment-card .author-section p {
    margin: 0px;
}

.comment-card .author-section .avatar-section {
    margin-right: 10px;
}

.comment-card .author-section .comment-card-avatar {
    width: 30px;
    height: 30px;
    border-radius: 100%;
    background-color: #002440;
}

.comment-card .author-section .comment-card-avatar-img {
    width: 100%;
    height: 100%;
    border-radius: 100%;
    background-color: #002440;
}

.comment-card .author-section .author-data-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.comment-card .author-section .author-name {
    font-size: 16px;
    font-weight: bold;
    color: #000;
}

.comment-card a:hover {
    text-decoration: none;
}

.comment-card .author-section .author-summary {
    font-size: 14px;
    color: #000;
}

.comment-card .title-section > h2 {
    margin: 0px 0px 10px 0px;
}

.comment-card .post-description-section {
    margin-bottom: 20px;
    font-size: 16px;
}

.comment-card .post-description-section > p {
    margin: 0px;
}

.comment-card .post-meta-data-section {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    margin-bottom: 10px;
    font-size: 14px;
}

.comment-card .post-meta-data-section .left-side {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.comment-card .post-meta-data-section .left-side > div:not(:last-child) {
    margin-right: 10px;
}

.comment-card .reaction-summary {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

.comment-card .reaction-emoji {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-right: 2px;
    padding: 4px 10px;
    background-color: #f5f5f5;
    border-top-left-radius: 50px;
    border-bottom-left-radius: 50px;
    border-top-right-radius: 50px;
    border-bottom-right-radius: 50px;
}

.comment-card .reaction-emoji.has-reacted {
    background-color: #fee2a2;
}

.comment-card .reaction-emoji .emoji-icon {
    display: inline-block;
    font-size: 18px;
}

.comment-card .reaction-emoji .reaction-count {
    display: inline-block;
    margin-top: -2px;
    font-family: "Open Sans";
    font-size: 11px;
}

.comment-card .post-meta-data-section p {
    margin: 0px;
}

.comment-card .comment-body {
    white-space: pre-line;
}

.comment-card .comment-bar {
    padding: 5px;
    background-color: #eee;
    border: solid 0.5px #ddd;
    border-radius: 2px;
}

.comment-card .view-replies-button {
    font-family: times, serif;
    font-weight: bold;
    font-size: 14px;
    color: #007cb4;
}

.comment-card .view-replies-button:hover {
    cursor: pointer;
    text-decoration: underline;
}

.comment-card .reply-button {
    display: flex;
    align-items: center;
}

.comment-card .reply-icon {
    width: 20px;
    height: 20px;
    margin-right: 2px;
}

.comment-card .first-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.comment-card .dropdown {
    top: 40px;
}

.comment-card .more-options-icon {
    width: 20px;
    height: 20px;
}

.comment-card .option-icon {
    width: 18px;
    height: 18px;
    margin-right: 12px;
}

.comment-card .dropdown .option-button {
    width: 100%;
    padding: 15px 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: left;
}

.comment-card .dropdown .option-button:not(:last-child) {
    border-bottom: solid 1px #ddd;
}

.comment-card .hide-options-form .hide-form-header {
    height: 40px;
    padding: 0px 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    box-shadow: 0px 2px 4px 0px #e5e5e5;
}

.comment-card .hide-options-form .back-arrow-icon {
    width: 20px;
    height: 20px;
}

.comment-card .hide-options-form .hide-reasons-container {
    padding-top: 15px;
    padding-bottom: 5px;
    padding-left: 15px;
}

.comment-card .hide-options-form .hide-reason-button {
    width:  initial;
    height: initial;
    margin-right: 10px;
    margin-bottom: 10px;
    padding: 5px 10px;
    display: inline-block;
    background-color: #eee;
    border: solid 2px #ccc;
    border-radius: 25px;
}

.comment-card .hide-options-form .other-reason-input {
    width: 80%;
    padding: 10px;
    border: solid 1px #ccc;
    border-radius: 5px;
    font-size: 12px;
}

.comment-card .hide-options-form .other-reason-input-container {
    padding: 0px 15px;
    margin-bottom: 15px;
}

.comment-card .hide-options-form .hide-form-footer {
    height: 40px;
    padding: 0px 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    box-shadow: 0px -2px 4px 0px #e5e5e5;
    text-align: right;
}

.comment-card .hide-options-form .hide-form-footer .submit-button {
    padding: 5px 20px;
    background-color: #004fc5;
    border-radius: 4px;
    box-shadow: 0px 0px 4px 0px #aaa;
    color: #fff;
    font-size: 12px;
    font-weight: bold;
}

.comment-card .edit-comment-textarea {
    width: 100%;
    padding: 10px;
    resize: none;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    background-color: #f0f0f0;
    border: solid 1px #ccc;
    border-radius: 2px;
    font-size: 12px;
}

.comment-card .comment-edit-form-buttons-container {
    margin: 10px 0px;
    text-align: right;
}

.comment-card .comment-edit-form-button:first-child {
    margin-right: 10px;
}

.comment-card .comment-edit-form-button {
    padding: 5px 20px;
    background-color: #1877c3;
    border-radius: 4px;
    box-shadow: 0px 0px 4px 0px #aaa;
    color: #fff;
    font-size: 10px;
    font-weight: bold;
}

.comment-card .comment-edit-form-button.cancel-button {
    background-color: #888;
}
