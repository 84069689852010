.post-card {
    position: relative;
    /*background-color: #fff;
    border-radius: 4px;
    border-top: solid 1px #e5e5e5;
    border-bottom: solid 1px #e0e0e0;
    box-shadow: 0px 0px 4px 0px #dedede;*/
}

.post-card a:hover {
    text-decoration: none;
}

.post-card .dot-separator {
    margin: 0px 10px;
}

.post-card .post-creation-date {
    margin: 0px;
    font-size: 12px;
    color: #555;
}

.post-card .post-group-link {
    font-size: 12px;
    color: #555;
}

.post-card .post-group-link:hover {
    text-decoration: underline;
}

.post-card .post-group-link .group-name {
    font-weight: bold;
}

.post-card .first-row {
    padding: 2px 10px 2px 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: #f0f0f0;
    border-bottom: 1px solid #e8e8e8;
}

.post-card .author-section {
    display: flex;
    flex-wrap: nowrap;
    margin-bottom: 10px;
    padding: 10px 10px 0px 10px;
}

.post-card .author-section p {
    margin: 0px;
}

.post-card .author-section .avatar-section {
    margin-right: 10px;
}

.post-card .author-section .author-data-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.post-card .author-section .post-card-avatar {
    width: 30px;
    height: 30px;
    border-radius: 100%;
    background-color: #002440;
}

.post-card .author-section .post-card-avatar-img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
}

.post-card .author-section .author-name {
    font-size: 16px;
    font-weight: bold;
    color: #000;
}

.post-card .author-section .author-name:hover {
    text-decoration: none;
}

.post-card .author-section .author-summary {
    font-size: 14px;
    color: #444;
}

.post-card .author-section .author-summary:hover {
    text-decoration: none;
}

.post-card .author-section a:hover {
    text-decoration: none;
}

.post-card .title {
    padding: 10px;
    white-space: pre-line;
    margin: 0px;
    color: #000;
}

.post-card .title:hover {
    text-decoration: none;
}

.post-card .description {
    padding: 10px;
    white-space: pre-line;
    margin: 0px;
    font-size: 17px;
    color: #000;
    text-decoration: none;
}

.post-card a.description:hover {
    text-decoration: none;
}

.post-card .description-section {
    margin-bottom: 20px;
    padding: 0px 10px;
    font-size: 16px;
}

.post-card .description-section > p {
    margin: 0px;
}

.post-card .post-photo-container {
    width: 100%;
    background-color: #fff;
    text-align: center;
}

.post-card .post-photo {
    width: 100%;
    height: auto;
}

.post-card .post-link-element {
    width: 100%;
    display: block;
    color: #000;
}

.post-card .post-meta-data-section {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    margin: 0px 10px;
    padding: 10px 0px 15px 0px;
    border-bottom: solid 0.8px #dfdfdf;
    font-size: 14px;
}

.post-card .post-meta-data-section p {
    margin: 0px;
}

.post-card .post-meta-data-section .reaction-summary {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

.post-card .reaction-select-section-container {
    padding: 0px 10px;
}

.post-card .reaction-emoji {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-right: 2px;
    padding: 4px 10px;
    background-color: #f5f5f5;
    border-top-left-radius: 50px;
    border-bottom-left-radius: 50px;
    border-top-right-radius: 50px;
    border-bottom-right-radius: 50px;
}

.post-card .reaction-emoji.has-reacted {
    background-color: #fee2a2;
}

.post-card .reaction-emoji .emoji-icon {
    display: inline-block;
    font-size: 18px;
}

.post-card .reaction-emoji .reaction-count {
    display: inline-block;
    margin-top: -2px;
    font-family: "Open Sans";
    font-size: 11px;
}

.post-card .reaction-select-section {
    display: flex;
    flex-direction: row;
    padding: 5px 0px;
}

.post-card .reaction-select-section .reaction-selector-button-container {
    flex-grow: 1;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.post-card .reaction-select-section .reaction-selector-button-container:hover {
    background-color: #efefef;
    cursor: pointer;
}

.post-card .reaction-select-section .comment-button {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 100%;
    font-size: 14px;
    font-weight: bold;
    color: #555;
    padding: 0px;
}

.post-card .reaction-select-section .view-post-link {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 100%;
    font-size: 14px;
    font-weight: bold;
    color: #555;
    padding: 0px;
}

.post-card .comment-bar {
    padding: 5px;
    background-color: #eee;
    border: solid 0.5px #ddd;
    border-radius: 2px;
}

.post-card .comment-bar p.placeholder {
    margin: 0px;
    font-size: 14px;
}

.post-card .num-comments-button {
    display: flex;
    align-items: center;
}

.post-card .comment-box-icon {
    width: 14px;
    height: 14px;
    margin-right: 5px;
    vertical-align: middle;
}

.post-card .tags-section {
    padding: 10px 10px 0px 10px;
}

.post-card .tag-button-container {
    display: inline-block;
    margin-right: 10px;
    margin-bottom: 10px;
}


.post-card .tag-button {
    padding: 5px 10px 5px 10px;
    display: inline-block;
    background-color: #ffd196;
    border: solid 2px #f79f2e;
    border-radius: 50px;
    color: #000;
}

.post-card .more-options-icon {
    width: 20px;
    height: 20px;
}

.post-card .option-icon {
    width: 18px;
    height: 18px;
    margin-right: 12px;
}

.post-card .dropdown {
    top: 40px;
}

.post-card .dropdown .option-button {
    width: 100%;
    padding: 15px 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: left;
}

.post-card .dropdown .option-button:not(:last-child) {
    border-bottom: solid 1px #ddd;
}

.post-card .hide-options-form .hide-form-header {
    height: 40px;
    padding: 0px 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    box-shadow: 0px 2px 4px 0px #e5e5e5;
}

.post-card .hide-options-form .back-arrow-icon {
    width: 20px;
    height: 20px;
}

.post-card .hide-options-form .hide-reasons-container {
    padding-top: 15px;
    padding-bottom: 5px;
    padding-left: 15px;
}

.post-card .hide-options-form .hide-reason-button {
    width:  initial;
    height: initial;
    margin-right: 10px;
    margin-bottom: 10px;
    padding: 5px 10px;
    display: inline-block;
    background-color: #eee;
    border: solid 2px #ccc;
    border-radius: 25px;
}

.post-card .hide-options-form .other-reason-input {
    width: 80%;
    padding: 10px;
    border: solid 1px #ccc;
    border-radius: 5px;
    font-size: 12px;
}

.post-card .hide-options-form .other-reason-input-container {
    padding: 0px 15px;
    margin-bottom: 15px;
}

.post-card .hide-options-form .hide-form-footer {
    height: 40px;
    padding: 0px 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    box-shadow: 0px -2px 4px 0px #e5e5e5;
    text-align: right;
}

.post-card .hide-options-form .hide-form-footer .submit-button {
    padding: 5px 20px;
    background-color: #004fc5;
    border-radius: 4px;
    box-shadow: 0px 0px 4px 0px #aaa;
    color: #fff;
    font-size: 12px;
    font-weight: bold;
}
