#do-not-sell .page-body-container {
    max-width: 550px;
    padding: 20px;
    margin: 0px auto;
    margin-top: 57px;
}

#do-not-sell form {
    padding: 20px;
    border: solid 1px #ccc;
    border-radius: 4px;
}

#do-not-sell .submit-button-container {
    margin-top: 20px;
    text-align: center;
}

#do-not-sell .submit-button {
    background-color: #045698;
    color: #fff;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    border-radius: 4px;
    padding: 10px 20px;
}

#do-not-sell .submit-button:hover {
    background-color: #168be8;
}

#do-not-sell .submit-button:disabled {
    background-color: #368c39;
    cursor: default;
}
