.add-emoji-container {
    background-color: #fff;
    border-top-left-radius: 50px;
    border-bottom-left-radius: 50px;
    border-top-right-radius: 50px;
    border-bottom-right-radius: 50px;
    box-shadow: 0px 0px 5px 0px #888;
    overflow: hidden;
    box-sizing: content-box;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.add-emoji-container button {
    height: 50px;
    width: 50px;
    font-size: 22px;
    transition: font-size 0.1s;
}

.add-emoji-container button:not(:last-child) {
    margin-right: 5px;
}

.emoji-selector .add-emoji-button {
    width: 100%;
    height: 100%;
    position: relative;
    font-size: 16px;
}

.emoji-selector .add-emoji-button:hover {
    cursor: pointer;
    background-color: #efefef;
}

.emoji-selector .add-emoji-button .select-icon {
    display: block;
    padding-top: 3px;
}

.emoji-selector .add-emoji-button .select-icon.reaction-is-set {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.emoji-selector .add-emoji-button .select-icon .smile-icon {
    font-size: 16px;
}

.emoji-selector .plus-icon {
    position: absolute;
    font-size: 10px;
}

.emoji-selector .add-emoji-button .select-icon .emoji-symbol {
    font-size: 22px;
}

.emoji-selector .add-emoji-button .select-icon .emoji-title {
    margin-left: 2px;
    font-size: 14px;
    font-weight: bold;
    font-style: italic;
    color: #555;
}

.emoji-selector .add-emoji-button .select-icon .emoji-title.thumbs_up {
    color: #2c2c2c;
}

.emoji-selector .add-emoji-button .select-icon .emoji-title.fire {
    color: #dc8403;
}

.emoji-selector .add-emoji-button .select-icon .emoji-title.one_hundred {
    color: #ff0000;
}

.emoji-selector .add-emoji-button .select-icon .emoji-title.haha {
    color: #d6a106;
}

.emoji-selector .add-emoji-button .select-icon .emoji-title.trash {
    color: #966322;
}

@media screen and (min-height: 630px) {
    .add-emoji-container button:hover {
        cursor: pointer;
        font-size: 32px;
    }
}
