.global-header {
    width: 100%;
    height: 57px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: #000;
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 800;
}

.global-header a {
    color: #fff;
    text-decoration: none;
}

.global-header a:hover {
    color: #fff;
    text-decoration: none;
}

.global-header .global-header-container {
    width: 100%;
    max-width: 550px;
    margin: 0px auto;
    position: relative;
}

.global-header .logo-container {
    padding-left: 10px;
}

.global-header .logo-row {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
}

.global-header .logo {
    margin: 0px;
    color: #fff;
    font-family: Open Sans,-apple-system,helvetica,arial,sans-serif;
    font-size: 24px;
}

.global-header .showmore-nav-icon-container {
    width: 20px;
    height: 20px;
}

.global-header .showmore-nav-icon {
    width: 20px;
    height: 20px;
}

.global-header .right-side {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-right: 10px;
}

.global-header .nav-item {
    position: relative;
    color: #fff;
}

.global-header .nav-item:hover {
    cursor: pointer;
    color: #ddd;
}

.global-header .nav-item:not(:last-child) {
    padding: 0px 12px;
}

.global-header .nav-item.profile-button {
    border-right: solid 0.8px #434343;
    font-weight: bold;
}

.global-header .nav-item.show-more-button {
    margin-left: 10px;
}

.global-header .nav-item.button {
    border: solid 1px #fff;
    border-radius: 2px;
    text-align: center;
    white-space: nowrap;
}

.global-header .nav-item.button:hover {
    background-color: #fff;
    color: #000;
}

.global-header .nav-item.button.signup {
    margin-left: 5px;
}

.global-header .dropdown-container {
    width: 100%;
    background-color: #000;
    position: absolute;
    box-sizing: border-box;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
}

.global-header .exit-demo-button {
    margin-right: 20px;
    padding: 5px 10px;
    background-color: #383838;
    border-radius: 5px;
    font-size: 14px;
    font-weight: bold;
    color: #fff;
}

.global-header .icon-button-badge {
    margin-left: -5px;
    margin-top: -10px;
    position: absolute;
    background-color: #ff0000;
    border-radius: 5px;
    padding: 2px 5px;
    font-size: 12px;
    font-weight: bold;
}

@media (min-width: 500px) {
    .global-header .logo-container {
        padding-left: 0px;
    }

    .global-header .right-side {
        padding-right: 0px;
    }
}
