.compose-header {
    width: 100%;
    height: 57px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: #000;
}

.compose-header .compose-header-inner-container {
    width: 100%;
    max-width: 550px;
    margin: 0px auto;
}

.compose-header .logo {
    margin: 0px;
    color: #fff;
}

.compose-header .showmore-nav-icon {
    width: 20px;
    height: 20px;
}

.compose-header .right-side {
    text-align: right;
}

.compose-header .cancel-button {
    padding: 0px 20px;
    color: #fff;
    font-size: 14px;
    font-weight: bold;
}

.compose-header .send-post-button {
    padding-right: 10px;
    padding-left: 20px;
    color: #fff;
    font-size: 14px;
    font-weight: bold;
}

.compose-header .send-post-button.disabled {
    color: #aaa;
    outline: none;
}

@media (min-width: 500px) {
    .compose-header .send-post-button {
        padding-right: 0px;
    }
}
