#password-reset .page-body-container {
    max-width: 550px;
    margin: 0px auto;
    margin-top: 57px;
    padding: 20px 20px 0px 20px;
}

#password-reset .header {
    width: 100%;
    position: fixed;
    top: 0px;
    left: 0px;
    padding: 10px 20px;
    letter-spacing: 1px;
    background-color: #000;
}

#password-reset .header .header-inner-container {
    max-width: 1200px;
    margin: 0px auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

#password-reset .logo {
    margin-bottom: 50px;
    font-weight: bold;
    font-size: 38px;
    color: #fff;
}

#password-reset .logo {
    margin: 0px;
    font-size: 24px;
    font-family: "Open Sans", -apple-system, helvetica, arial, sans-serif;
    letter-spacing: -1px;
    font-weight: 800;
    color: #fff;
}

#password-reset .form-wrapper {
    margin-top: 20px;
}

#password-reset .label {
    font-weight: bold;
    font-size: 16px;
}

#password-reset .form-field-container {
    margin-bottom: 20px;
}

#password-reset .input-field {
    width: 100%;
    padding: 10px 10px;
    border: solid 1px #333;
    border-radius: 3px;
    box-sizing: border-box;
}

#password-reset .submit-button {
    padding: 10px 30px;
    border-radius: 4px;
    background-color: #323232;
    color: #fff;
    font-size: 16px;
    font-weight: bold;
}

#password-reset .submit-button:hover {
    background-color: #000;
}

#password-reset .form-link {
    margin-right: 20px;
    font-weight: bold;
    color: #116bb3;
}

#password-reset .form-link:hover {
    text-decoration: underline;
}

#password-reset .invalid-link-message-container {
    margin-top: 100px;
    text-align: center;
}

#password-reset .login-link {
    color: #116bb3;
    font-weight: bold;
    text-decoration: underline;
}

#password-reset .error {
    display: block;
    margin-top: 5px;
    color: #ff0000;
}
