#categories .page-body-container {
    max-width: 550px;
    margin: 0px auto;
    position: relative;
    margin-top: 20px;
}

#categories .alphabet-section {
    margin-bottom: 20px;
}

#categories .category-list-container {
    max-width: 550px;
    margin: 0px auto;
    padding: 0px 20px 20px 20px;
}

#categories .category-button-container {
    display: inline-block;
    margin-right: 10px;
    margin-bottom: 10px;
}


#categories .category-button {
    padding: 5px 10px 5px 10px;
    display: inline-block;
    background-color: #ffd196;
    border: solid 2px #f79f2e;
    border-radius: 50px;
    color: #000;
}

#categories .category-button:hover {
    background-color: #e68100;
}
