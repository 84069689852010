html {
    width: 100%;
    height: 100%;
    margin: 0px;
    padding: 0px;
}

body {
    width: 100%;
    height: 100%;
    min-height: 100%;
    margin: 0px;
    padding: 0px;
    font-family: times, serif, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
        "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #fff;
}

div#root {
    width: 100%;
    height: 100%;
}

div.App {
    height: 100%;
    width: 100%;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
    color: #045698;
    text-decoration: none;
}

a:hover {
    text-decoration: underline;
}

h1, h2, h3, h4, h5, p {
    margin: 0px 0px 10px 0px;
}

button {
    background-color: transparent;
    border: none;
}

button:hover {
    cursor: pointer;
}

input, textarea, select {
    font-size: 16px;
}
